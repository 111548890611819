import { StringValidators } from "./constants";
import { availableSymbolsValidator } from "./available-symbols";
import { lengthLimitValidator } from "./length-limit";
import { spacesValidator } from "./spaces";
import { uuidValidator } from "./uuid";
import { optionalValidator } from "./optional";
import { emailValidator } from "./email";
import { urlValidator } from "./url";

export const stringValidatorsByType = {
  [StringValidators.AVAILABLE_SYMBOLS]: availableSymbolsValidator,
  [StringValidators.SPACES]: spacesValidator,
  [StringValidators.LENGTH_LIMIT]: lengthLimitValidator,
  [StringValidators.UUID]: uuidValidator,
  [StringValidators.OPTIONAL]: optionalValidator,
  [StringValidators.EMAIL]: emailValidator,
  [StringValidators.URL]: urlValidator,
} as const;
